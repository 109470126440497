import React, { Component } from 'react';
import axios from 'axios';

import TestImage from './test-image.png'

const ImageRating = {
    Ok: {text: 'OK, not scratched'},
    SlightlyScratched: {text: 'Slightly scratched, would make a delivery'},
    VeryScratched: {text: 'Very scratched, not drivable'},
    Unknown: {text: 'Cannot review / cannot see the image'},
};

const Image = (props) => {
    if (props.imageLoaded) {
        return <img className='responsive-image' src={TestImage} alt='camera image'></img>
    } else {
        return <p className="text-center">image loading...</p>
    }
}

const ImageRatingForm = (props) => {
    if (props.imageLoaded) {
        const choices = [ImageRating.Ok, ImageRating.SlightlyScratched, ImageRating.VeryScratched, ImageRating.Unknown]
        const buttons = choices.map((choice, index) => {
            return (
                <button className="full-button" key={index}>{choice.text}</button>
            )
        })
        return <div>{buttons}</div>
    } else {
        return <div></div>
    }
}

class CameraImageRatingForm extends Component {
    state = {
        imageLoaded: false,
        receiveMessageResponse: null,
        imageRating: ImageRating.Unknown,
    }

    componentDidMount() {
        const url = 'https://jvmk09b4af.execute-api.us-west-2.amazonaws.com/dev/image'
        
        axios.get(url)
            .then(function (response) {
              console.log(response);
              this.setState({ receiveMessageResponse:  response});
            })
            .catch(function (error) {
              console.log(error);
            })
    }

    render() {
        const { imageLoaded } = this.state
        return (
            <div id="cameraImageRatingForm" className='container'>
                <Image imageLoaded={imageLoaded} />
                <ImageRatingForm imageLoaded={imageLoaded} />
            </div>
        );
    }
}

export default CameraImageRatingForm;